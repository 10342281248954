import axios from 'axios'; // Or fetch if you're not using axios
import { API_ENDPOINT_URL } from 'configs/AppConfig'; // Make sure this points to the correct base URL

// Function to get user agent details
const getUserAgentDetails = () => {
    const userAgent = navigator.userAgent;
    let browserName = 'Unknown';
    let osName = 'Unknown';

    // Detect the browser name
    if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'Chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'Firefox';
    } else if (userAgent.match(/safari/i)) {
        browserName = 'Safari';
    } else if (userAgent.match(/opr\//i)) {
        browserName = 'Opera';
    } else if (userAgent.match(/edg/i)) {
        browserName = 'Edge';
    }

    // Detect the OS name
    if (userAgent.match(/win/i)) {
        osName = 'Windows';
    } else if (userAgent.match(/mac/i)) {
        osName = 'MacOS';
    } else if (userAgent.match(/linux/i)) {
        osName = 'Linux';
    } else if (userAgent.match(/android/i)) {
        osName = 'Android';
    } else if (userAgent.match(/iphone|ipad|ipod/i)) {
        osName = 'iOS';
    }

    return { userAgent, browserName, osName };
};

// Function to send the heartbeat request
export const sendHeartbeat = async () => {
    const { userAgent, browserName, osName } = getUserAgentDetails();

    const systemInfo = JSON.parse(localStorage?.getItem('auth_user'))?.systemInfo?.[0];

    const payload = {
        user_agent: userAgent,
        os: osName,
        browser: browserName,
        systemInfo: systemInfo,
    };

    try {
        const response = await axios.post(`${API_ENDPOINT_URL}/heartbeat`, payload);

        if (response.data.status === 'ok') {
            console.log('Heartbeat sent successfully:', response.data);
            return response.data; // Return the response if needed
        } else {
            console.error('Heartbeat failed:', response.data);
        }
    } catch (error) {
        console.error('Error sending heartbeat:', error);
    }
};
