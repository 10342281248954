import { useState, useEffect } from 'react';
import SoakDataService from 'services/SoakDataService';

const useAbilities = () => {
    const [abilities, setAbilities] = useState([]);

    useEffect(() => {
        const fetchAbilities = async () => {
            const data = SoakDataService.getData('abilities');
            setAbilities(data?.abilities || []);
        };
        fetchAbilities();
    }, []);

    const hasAbility = (ability) => abilities.includes(ability);

    return { abilities, hasAbility };
};

export default useAbilities;
