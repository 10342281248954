const dbName = 'persistedReduxState';
const storeName = 'persistStore';

// Open (or create) IndexedDB database
function openDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open(dbName, 1);
        request.onupgradeneeded = () => {
            request.result.createObjectStore(storeName);
        };
        request.onsuccess = () => resolve(request.result);
        request.onerror = (err) => reject(err);
    });
}

// Helper function to perform transaction
function withStore(type, callback) {
    return openDB().then(db => new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, type);
        const store = transaction.objectStore(storeName);
        const request = callback(store);
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
    }));
}

const indexedDBStorageEngine = {
    getItem(key) {
        return withStore('readonly', store => store.get(key));
    },
    setItem(key, value) {
        return withStore('readwrite', store => store.put(value, key));
    },
    removeItem(key) {
        return withStore('readwrite', store => store.delete(key));
    },
};

export default indexedDBStorageEngine;
