import fetch from 'auth/FetchInterceptor'
import { API_ENDPOINT_URL } from 'configs/AppConfig';

const AuthService = {}

AuthService.login = function (data) {
	return fetch({
		url: `${API_ENDPOINT_URL}/login`,
		method: 'post',
		data: data
	})
}

AuthService.changeOutlet = function (props) {
	const outletId = props.data;
	return fetch({
		url: `${API_ENDPOINT_URL}/outlet`,
		method: 'post',
		data: { 'outlet_id': outletId }
	})
}

AuthService.register = function (data) {
	return fetch({
		url: `${API_ENDPOINT_URL}/auth/register`,
		method: 'post',
		data: data
	})
}

AuthService.logout = function () {
	return fetch({
		url: `${API_ENDPOINT_URL}/logout`,
		method: 'post'
	})
}

AuthService.loginInOAuth = function () {
	return fetch({
		url: `${API_ENDPOINT_URL}/auth/loginInOAuth`,
		method: 'post'
	})
}

export default AuthService;