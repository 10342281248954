import { createSlice } from '@reduxjs/toolkit';

// Initial state for users
export const initialState = {
    users: [],
    userAvatarBase64: '', // Optional field if you need to handle user avatar uploads
};

// Creating the users slice with reducers
const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        clearUsers: (state) => {
            state.users = [];
        },
        refreshUsers: (state, action) => {
            const { id, newItem, actionType } = action.payload;
            state.users = state.users
                .map((item) => {
                    if (item.id === id) {
                        if (actionType === 'update') {
                            return newItem;
                        } else if (actionType === 'delete') {
                            return null; // Returning null will remove the item from the array
                        }
                    }
                    return item;
                })
                .filter((item) => item !== null); // Filter out deleted items
        },
        setUserAvatarBase64: (state, action) => {
            state.userAvatarBase64 = action.payload;
        },
        clearUserAvatarBase64: (state) => {
            state.userAvatarBase64 = '';
        },
    },
});

// Exporting the actions
export const { setUsers, clearUsers, refreshUsers, setUserAvatarBase64, clearUserAvatarBase64 } = usersSlice.actions;

// Exporting the reducer to be used in the store
export default usersSlice.reducer;
