// src/store/slices/registerSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    registers: [],
};

const registerSlice = createSlice({
    name: 'registers',
    initialState,
    reducers: {
        setRegisters: (state, action) => {
            state.registers = action.payload;
        },
        addRegister: (state, action) => {
            state.registers.push(action.payload);
        },
        updateRegister: (state, action) => {
            const { id, newItem } = action.payload;
            const index = state.registers.findIndex(register => register.id === id);
            if (index !== -1) {
                state.registers[index] = newItem;
            }
        },
        deleteRegister: (state, action) => {
            state.registers = state.registers.filter(register => register.id !== action.payload);
        },
    },
});

export const { setRegisters, addRegister, updateRegister, deleteRegister } = registerSlice.actions;

export default registerSlice.reducer;
