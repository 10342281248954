import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { publicRoutes } from 'configs/RoutesConfig';
import { getFilteredProtectedRoutes } from 'configs/RoutesConfig'; // Import the filtered routes helper
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import useAbilities from 'utils/hooks/useAbilities';

const Routes = () => {
	const { hasAbility } = useAbilities(); // Get the ability checker from the hook

	// Get filtered protected routes based on the user's abilities
	const filteredProtectedRoutes = getFilteredProtectedRoutes(hasAbility);

	return (
		<RouterRoutes>
			{/* Protected routes */}
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/" element={<Navigate replace to={AUTHENTICATED_ENTRY} />} />
				{filteredProtectedRoutes.map((route, index) => {
					return (
						<Route
							key={route.key + index}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					);
				})}
				<Route path="*" element={<Navigate to="/" replace />} />
			</Route>

			{/* Public routes */}
			<Route path="/" element={<PublicRoute />}>
				{publicRoutes.map((route) => {
					return (
						<Route
							key={route.path}
							path={route.path}
							element={
								<AppRoute
									routeKey={route.key}
									component={route.component}
									{...route.meta}
								/>
							}
						/>
					);
				})}
			</Route>
		</RouterRoutes>
	);
};

export default Routes;
