import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  unitOfMeasures: [],
};

const unitOfMeasureSlice = createSlice({
  name: 'unitOfMeasures',
  initialState,
  reducers: {
    setUnitOfMeasures: (state, action) => {
      state.unitOfMeasures = action.payload;
    },
    clearUnitOfMeasures: (state) => {
      state.unitOfMeasures = [];
    },
    refreshUnitOfMeasures: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.unitOfMeasures = state.unitOfMeasures.map(item => {
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

export const { setUnitOfMeasures, clearUnitOfMeasures, refreshUnitOfMeasures } = unitOfMeasureSlice.actions;
export default unitOfMeasureSlice.reducer;
