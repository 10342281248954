import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  inventoryProducts: [],
};

const inventoryProductSlice = createSlice({
  name: 'inventoryProducts',
  initialState,
  reducers: {
    setInventoryProducts: (state, action) => {
      state.inventoryProducts = action.payload;
    },
    clearInventoryProducts: (state) => {
      state.inventoryProducts = [];
    },
    // This is good for whole product list
    // refreshInventoryProducts: (state, action) => {
    //   const updatedProduct = action.payload;
    //   // Ensure the product has an ID and exists in the state
    //   if (!updatedProduct || !updatedProduct.id) {
    //     console.error("Invalid product update data", updatedProduct);
    //     return;
    //   }
    //   // Check if the product exists in the current inventory
    //   const productIndex = state.inventoryProducts.findIndex(item => item.id === updatedProduct.id);
    //   if (productIndex !== -1) {
    //     // Update the product, merging the new properties with the existing ones
    //     state.inventoryProducts[productIndex] = {
    //       ...state.inventoryProducts[productIndex], // Existing product
    //       ...updatedProduct // Updated fields
    //     };
    //   } else {
    //     // If the product doesn't exist, log an error or add it
    //     console.error(`Product with id ${updatedProduct.id} not found in inventory`);
    //   }
    // },
    // This is good for stock relatedd products update
    refreshInventoryProducts: (state, action) => {
      const updatedProduct = action.payload;
      // Update the single product in the state by matching its id
      state.inventoryProducts = state.inventoryProducts.map(item => {
        if (item.id === updatedProduct.id) {
          // Replace the entire product with the updated one
          return updatedProduct;
        }
        return item; // Keep the product unchanged if not affected
      });
    },

    addInventoryProduct: (state, action) => {
      state.inventoryProducts.push(action.payload);
    },
    updateInventoryProduct: (state, action) => {
      const { id, newItem } = action.payload;
      state.inventoryProducts = state.inventoryProducts.map(item => item.id === id ? newItem : item);
    },













  },
});

export const { partialUpdateInventoryProduct, setInventoryProducts, clearInventoryProducts, refreshInventoryProducts, addInventoryProduct, updateInventoryProduct } = inventoryProductSlice.actions;
export default inventoryProductSlice.reducer;
