import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setNetworkStatus } from 'store/slices/networkStatusSlice';
import { notification } from 'antd';
import echo from './Echo'; // Ensure this is correctly imported
import { API_ENDPOINT_URL } from 'configs/AppConfig';
import fetch from 'auth/FetchInterceptor';
import { ProcessRecords } from 'services/OfflineAPIRequestHandler'; // Import the ProcessRecords function
import { useQueryClient } from '@tanstack/react-query'; // Import useQueryClient
import { setNotificationAlerts } from 'store/slices/notificationSlice';

const CHECK_INTERVAL_OFFLINE = 5000; // Check every 5 seconds when offline
const CHECK_INTERVAL_ONLINE = 60000; // Check every 15 seconds when online
const API_URL = `${API_ENDPOINT_URL}/heartbeat`;

const NetworkStatusManager = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient(); // Initialize queryClient
    const [isOffline, setIsOffline] = useState(false); // Track if currently offline
    const [hasBeenOffline, setHasBeenOffline] = useState(false);
    const [lastNotificationType, setLastNotificationType] = useState(null);
    let intervalId;

    // Show notifications with antd
    const openNotificationWithIcon = (type, message, description) => {
        if (type === lastNotificationType) return; // Prevent duplicate notifications
        notification[type]({
            message,
            description,
        });
        setLastNotificationType(type);
    };

    // Utility to fetch user agent details
    const getUserAgentDetails = () => {
        const userAgent = navigator.userAgent;
        let browserName = 'Unknown';
        let osName = 'Unknown';

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = 'Chrome';
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = 'Firefox';
        } else if (userAgent.match(/safari/i)) {
            browserName = 'Safari';
        } else if (userAgent.match(/opr\//i)) {
            browserName = 'Opera';
        } else if (userAgent.match(/edg/i)) {
            browserName = 'Edge';
        }

        if (userAgent.match(/win/i)) {
            osName = 'Windows';
        } else if (userAgent.match(/mac/i)) {
            osName = 'MacOS';
        } else if (userAgent.match(/linux/i)) {
            osName = 'Linux';
        } else if (userAgent.match(/android/i)) {
            osName = 'Android';
        } else if (userAgent.match(/iphone|ipad|ipod/i)) {
            osName = 'iOS';
        }

        return { userAgent, browserName, osName };
    };

    // Function to update the network status and dispatch actions
    const updateNetworkStatus = useCallback((isOnline) => {
        dispatch(setNetworkStatus(isOnline));
        setIsOffline(!isOnline);
    }, [dispatch]);

    // Function to check for version updates
    const checkForVersionUpdate = (response) => {
        // Get current version from local storage
        const authUser = JSON.parse(localStorage.getItem('auth_user'));
        const currentVersion = authUser?.systemInfo?.[0]?.app_version;

        // Ensure 'versionUpdateNotified' is initialized in localStorage
        const versionUpdateNotified = localStorage.getItem('versionUpdateNotified') || 'false';
        if (versionUpdateNotified === 'false' && response?.data?.app_version !== currentVersion) {
            // Dispatch notification for version update
            dispatch(setNotificationAlerts({ notification_type: 'version_update', data: response }));

            // Mark notification as sent
            localStorage.setItem('versionUpdateNotified', 'true');
        } else if (response?.data?.app_version === currentVersion) {
            // Reset notification state if versions match (optional)
            localStorage.setItem('versionUpdateNotified', 'false');
        }
    };

    // Function to handle heartbeat API calls
    const sendHeartbeat = async () => {
        const { userAgent, browserName, osName } = getUserAgentDetails();
        try {
            const systemInfo = JSON.parse(localStorage?.getItem('auth_user'));
            const payload = {
                user_agent: userAgent,
                os: osName,
                browser: browserName,
                systemInfo: systemInfo?.systemInfo?.[0],
            };

            const response = await fetch({
                url: API_URL,
                method: 'post',
                data: payload,
            });

            if (response.status === 'ok') {
                if (isOffline) {
                    openNotificationWithIcon('success', 'Online Mode', 'You are online!');
                    ProcessRecords(queryClient, ['products', 'orders']); // Process offline records
                }
                updateNetworkStatus(true);
                checkForVersionUpdate(response, systemInfo);
            } else {
                if (!isOffline) {
                    openNotificationWithIcon('error', 'Offline Mode', 'You are offline!');
                }
                updateNetworkStatus(false);
            }
        } catch (error) {
            if (!isOffline) {
                openNotificationWithIcon('error', 'Offline Mode', 'You are offline!');
            }
            updateNetworkStatus(false);
        }
    };

    // Function to handle socket connection state
    const manageWebSocketConnection = (pusherConnection) => {
        // Connection established
        pusherConnection.bind('connected', () => {
            console.log('Pusher connected');
            if (hasBeenOffline) {
                openNotificationWithIcon('success', 'Online Mode', 'You are online!');
                ProcessRecords(queryClient, ['products', 'orders']); // Process offline records
            }
            updateNetworkStatus(true);
        });

        // Connection state changes
        pusherConnection.bind('state_change', (states) => {
            console.log('Pusher state changed:', states);
            if (states.current !== 'connected') {
                setHasBeenOffline(true);
                updateNetworkStatus(false);
            }
        });

        // Connection errors
        pusherConnection.bind('error', (err) => {
            console.error('Pusher error:', err);
            openNotificationWithIcon('error', 'Connection Error', 'There was an error with the connection!');
            updateNetworkStatus(false);
        });

        // Handle disconnections
        pusherConnection.bind('disconnected', () => {
            console.log('Pusher disconnected');
            updateNetworkStatus(false);
            setHasBeenOffline(true);
        });
    };

    // UseEffect for WebSocket management and heartbeat
    useEffect(() => {
        const pusherConnection = echo.connector.pusher.connection;
        manageWebSocketConnection(pusherConnection);

        const manageHeartbeat = () => {
            clearInterval(intervalId);
            intervalId = setInterval(sendHeartbeat, isOffline ? CHECK_INTERVAL_OFFLINE : CHECK_INTERVAL_ONLINE);
        };

        manageHeartbeat(); // Manage heartbeat initially

        return () => {
            pusherConnection.unbind('connected');
            pusherConnection.unbind('state_change');
            pusherConnection.unbind('error');
            clearInterval(intervalId); // Clear interval on unmount
        };
    }, [dispatch, isOffline, hasBeenOffline, queryClient, updateNetworkStatus]);

    return null; // This component does not render anything
};

export default NetworkStatusManager;
