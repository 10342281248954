// permissionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const permissionSlice = createSlice({
    name: 'permissions',
    initialState: {
        permissions: [], // Ensure this state is initialized correctly
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload || []; // Safeguard to ensure permissions are always an array
        },
        addPermission: (state, action) => {
            state.permissions.push(action.payload);
        },
        updatePermission: (state, action) => {
            const index = state.permissions.findIndex((perm) => perm.id === action.payload.id);
            if (index !== -1) {
                state.permissions[index] = action.payload;
            }
        },
        deletePermission: (state, action) => {
            state.permissions = state.permissions.filter((perm) => perm.id !== action.payload);
        },
    },
});

export const { setPermissions, addPermission, updatePermission, deletePermission } = permissionSlice.actions;
export default permissionSlice.reducer;
