import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    miscCharges: [],
};

const miscChargeSlice = createSlice({
    name: 'miscCharges',
    initialState,
    reducers: {
        setMiscCharges: (state, action) => {
            state.miscCharges = action.payload;
        },
        refreshMiscCharges: (state, action) => {
            const { id, newItem, actionType } = action.payload;
            if (actionType === 'update') {
                state.miscCharges = state.miscCharges.map(charge =>
                    charge.id === id ? newItem : charge
                );
            } else if (actionType === 'delete') {
                state.miscCharges = state.miscCharges.filter(charge => charge.id !== id);
            } else if (actionType === 'create') {
                state.miscCharges.unshift(newItem);
            }
        },
    },
});

export const { setMiscCharges, refreshMiscCharges } = miscChargeSlice.actions;
export default miscChargeSlice.reducer;
