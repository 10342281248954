import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    settings: [],
    filteredSettings: [],
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action) => {
            state.settings = action.payload;
        },
        setFilteredSettings: (state, action) => {
            state.filteredSettings = action.payload;
        },
        clearFilteredSettings: (state) => {
            state.filteredSettings = [];
        },
        clearSettings: (state) => {
            state.settings = [];
        },
    },
});

export const { setSettings, clearSettings, setFilteredSettings, clearFilteredSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
