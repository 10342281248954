import { useProcessItemMutation } from 'services/APIRequestHandler';
import fetch from 'auth/FetchInterceptor';

const openDB = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('mutationQueue', 1);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore('requests', { keyPath: 'id', autoIncrement: true });
    };
  });
};

const fetchRecords = async (db) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['requests'], 'readonly');
    const objectStore = transaction.objectStore('requests');
    const request = objectStore.getAll();
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
};

const deleteRecord = async (db, id) => {
  console.log('Deleting record:', id);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['requests'], 'readwrite');
    const objectStore = transaction.objectStore('requests');
    const request = objectStore.delete(id);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = () => resolve();
  });
};

const ProcessRecords = async (queryClient, queryKeys) => {
  const { mutate } = useProcessItemMutation();
  const db = await openDB();
  const records = await fetchRecords(db);
  console.log('Fetched records:', records);

  if (records.length > 0) {
    for (const record of records) {
      const productId = Date.now();
      const newProduct = { [productId]: record.data };
      console.log('Processing record:', record.id);
      await mutate(record.url, record.method, record.data).catch(console.error); // Handle errors
      await deleteRecord(db, record.id);
    }

    // Invalidate the queries
    queryKeys.forEach((queryKey) => {
      queryClient.invalidateQueries(queryKey);
    });
  } else {
    console.log('No records to process.');
  }
};

export { ProcessRecords };
