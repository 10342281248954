import { xorDecrypt, getKeysFromLocalStorage } from 'services/EncryptionService';

const SoakDataService = {
  getData: (arrayKey, filterKey = '', filterValue = '', returnKey = '') => {
    try {
      const keys = getKeysFromLocalStorage();
      if (!keys) {
        throw new Error('Decryption keys not found');
      }
      const encryptedDataString = localStorage.getItem('soak_data');
      if (!encryptedDataString) {
        throw new Error('No encrypted data found');
      }
      // Clean the Base64 string to remove any invalid characters
      const cleanedDataString = encryptedDataString.replace(/[^A-Za-z0-9+/=]/g, '');
      // Decode the base64 string
      const decodedDataString = atob(cleanedDataString);
      // Decrypt the data
      const decryptedSoakData = xorDecrypt(decodedDataString, keys);
      const parsedSoakData = JSON.parse(decryptedSoakData);
      const dataArray = parsedSoakData[arrayKey];
      if (!dataArray) {
        throw new Error(`No data array found for key: ${arrayKey}`);
      }

      if (filterKey && filterValue) {
        const item = dataArray.find(item => item[filterKey] === filterValue);
        if (item) {
          return returnKey ? item[returnKey] : item;
        }
      }
      
      return dataArray;
    } catch (error) {
      console.error('Error getting data:', error);
      return null;
    }
  }
};

export default SoakDataService;
