import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import useAbilities from 'utils/hooks/useAbilities';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.index',
        path: `${APP_PREFIX_PATH}/dashboards/index`,
        component: React.lazy(() => import('views/app-views/dashboards/index')),
        visible: (hasAbility) => hasAbility('view-dashboard-menu'), // Ability check
    },
    {
        key: 'registers.index',
        path: `${APP_PREFIX_PATH}/sales/registers/index`,
        component: React.lazy(() => import('views/app-views/sales/registers/index')),
        visible: (hasAbility) => hasAbility('view-registers-menu'), // Ability check
    },
    {
        key: 'registers.details',
        path: `${APP_PREFIX_PATH}/sales/registers/details`,
        component: React.lazy(() => import('views/app-views/sales/registers/details')),
        visible: (hasAbility) => hasAbility('view-registers-menu'), // Ability check
    },
    {
        key: 'sales.index',
        path: `${APP_PREFIX_PATH}/sales/orders`,
        component: React.lazy(() => import('views/app-views/sales/orders/index')),
        visible: (hasAbility) => hasAbility('view-sales-menu'), // Ability check
    },
    {
        key: 'sales.order.history',
        path: `${APP_PREFIX_PATH}/sales/orders/history/order`,
        component: React.lazy(() => import('views/app-views/sales/orders/history/order')),
        visible: (hasAbility) => hasAbility('view-order-history-menu'), // Ability check
    },
    {
        key: 'sales.bill.history',
        path: `${APP_PREFIX_PATH}/sales/orders/history/bill`,
        component: React.lazy(() => import('views/app-views/sales/orders/history/bill')),
        visible: (hasAbility) => hasAbility('view-bill-history-menu'), // Ability check
    },






    {
        key: 'inventory.products',
        path: `${APP_PREFIX_PATH}/inventory/products`,
        component: React.lazy(() => import('views/app-views/inventory/products/index')),
        visible: (hasAbility) => hasAbility('view-products-menu'), // Ability check
    },
    {
        key: 'inventory.direct.stock.receive',
        path: `${APP_PREFIX_PATH}/inventory/stock/direct-stock-receive`,
        component: React.lazy(() => import('views/app-views/inventory/stock/directStockReceive')),
        visible: (hasAbility) => hasAbility('view-products-stock-receive-menu'), // Ability check
    },





    {
        key: 'master.categories',
        path: `${APP_PREFIX_PATH}/master/categories`,
        component: React.lazy(() => import('views/app-views/master/categories')),
        visible: (hasAbility) => hasAbility('view-master-categories-menu'), // Ability check
    },
    {
        key: 'master.outlets',
        path: `${APP_PREFIX_PATH}/master/outlets`,
        component: React.lazy(() => import('views/app-views/master/outlets')),
        visible: (hasAbility) => hasAbility('view-master-outlets-menu'), // Ability check
    },
    {
        key: 'master.businesses',
        path: `${APP_PREFIX_PATH}/master/businesses`,
        component: React.lazy(() => import('views/app-views/master/businesses')),
        visible: (hasAbility) => hasAbility('view-master-businesses-menu'), // Ability check
    },
    {
        key: 'master.floorplan',
        path: `${APP_PREFIX_PATH}/master/floorplan`,
        component: React.lazy(() => import('views/app-views/master/floorplan')),
        visible: (hasAbility) => hasAbility('view-master-floor-plans-menu'), // Ability check
    },
    {
        key: 'master.users',
        path: `${APP_PREFIX_PATH}/master/users`,
        component: React.lazy(() => import('views/app-views/master/users')),
        visible: (hasAbility) => hasAbility('view-master-users-menu'), // Ability check
    },
    {
        key: 'master.roles',
        path: `${APP_PREFIX_PATH}/master/roles`,
        component: React.lazy(() => import('views/app-views/master/roles')),
        visible: (hasAbility) => hasAbility('view-master-roles-menu'), // Ability check
    },
    {
        key: 'master.misc.charges',
        path: `${APP_PREFIX_PATH}/master/misc-charges`,
        component: React.lazy(() => import('views/app-views/master/miscCharges')),
        visible: (hasAbility) => hasAbility('view-master-misc-charges-menu'), // Ability check
    },
    {
        key: 'master.running.numbers',
        path: `${APP_PREFIX_PATH}/master/running-numbers`,
        component: React.lazy(() => import('views/app-views/master/runningNumbers')),
        visible: (hasAbility) => hasAbility('view-master-running-numbers-menu'), // Ability check
    },
    {
        key: 'master.registers',
        path: `${APP_PREFIX_PATH}/master/registers`,
        component: React.lazy(() => import('views/app-views/master/registers')),
        visible: (hasAbility) => hasAbility('view-master-registers-menu'), // Ability check
    },
    {
        key: 'master.settings',
        path: `${APP_PREFIX_PATH}/master/settings`,
        component: React.lazy(() => import('views/app-views/master/settings')),
        visible: (hasAbility) => hasAbility('view-master-settings-menu'), // Ability check
    },





    // customer
    {
        key: 'customers',
        path: `${APP_PREFIX_PATH}/customers`,
        component: React.lazy(() => import('views/app-views/customers/index')),
        visible: (hasAbility) => hasAbility('view-customers-menu'), // Ability check
    },
    // suppliers
    {
        key: 'suppliers',
        path: `${APP_PREFIX_PATH}/suppliers`,
        component: React.lazy(() => import('views/app-views/suppliers/index')),
        visible: (hasAbility) => hasAbility('view-suppliers-menu'), // Ability check
    },



    // reports
    {
        key: 'reports',
        path: `${APP_PREFIX_PATH}/reports/index`,
        component: React.lazy(() => import('views/app-views/reports/index')),
        visible: (hasAbility) => hasAbility('view-reports-menu'), // Ability check
    },



    //admin
    // {
    //     key: 'admin.onboarding',
    //     path: `${APP_PREFIX_PATH}/admin/onboarding`,
    //     component: React.lazy(() => import('views/app-views/admin/onboarding')),
    //     visible: (hasAbility) => hasAbility('admin-read-onboarding'), // Ability check
    // },
    {
        key: 'admin.businesses',
        path: `${APP_PREFIX_PATH}/admin/businesses`,
        component: React.lazy(() => import('views/app-views/admin/businesses')),
        visible: (hasAbility) => hasAbility('admin-read-businesses'), // Ability check
    },
    {
        key: 'admin.outlets',
        path: `${APP_PREFIX_PATH}/admin/outlets`,
        component: React.lazy(() => import('views/app-views/admin/outlets')),
        visible: (hasAbility) => hasAbility('admin-read-outlets'), // Ability check
    },
    {
        key: 'admin.users',
        path: `${APP_PREFIX_PATH}/admin/users`,
        component: React.lazy(() => import('views/app-views/admin/users')),
        visible: (hasAbility) => hasAbility('admin-read-users'), // Ability check
    },
    {
        key: 'admin.roles',
        path: `${APP_PREFIX_PATH}/admin/roles`,
        component: React.lazy(() => import('views/app-views/admin/roles')),
        visible: (hasAbility) => hasAbility('admin-read-roles'), // Ability check
    },
    {
        key: 'admin.permissions',
        path: `${APP_PREFIX_PATH}/admin/permissions`,
        component: React.lazy(() => import('views/app-views/admin/permissions')),
        visible: (hasAbility) => hasAbility('admin-read-permissions'), // Ability check
    },







]

// Create a helper function to filter protected routes
export const getFilteredProtectedRoutes = (hasAbility) => {
    return protectedRoutes.filter((route) => route.visible && route.visible(hasAbility));
};
