// XOR Decrypt function
export const xorDecrypt = (encryptedData) => {
    const keys = getKeysFromLocalStorage();
    const combinedKey = keys.join(''); // Combine keys to form the decryption key
    const keyLength = combinedKey.length;
    let decrypted = '';

    for (let i = 0; i < encryptedData.length; i++) {
        decrypted += String.fromCharCode(encryptedData.charCodeAt(i) ^ combinedKey.charCodeAt(i % keyLength));
    }

    return decrypted;
};

// Helper function to get keys from local storage
export const getKeysFromLocalStorage = () => {
    const keys = JSON.parse(localStorage.getItem('transport_keys'));
    return keys ? Object.values(keys) : null;
};