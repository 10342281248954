import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    backgroundProcesses: [], // Initialize as an array for background processes
    notificationAlerts: [],   // Separate array for reports/alerts
    showNotificationAlerts: false,
    showBackgroundProcessesNotification: false,
  },
  reducers: {
    setBackgroundProcesses: (state, action) => {
      state.backgroundProcesses = [action.payload, ...state.backgroundProcesses];
      state.showBackgroundProcessesNotification = true;
    },
    setShowBackgroundProcessesNotification: (state, action) => {
      state.showBackgroundProcessesNotification = action.payload;
    },
    setNotificationAlerts: (state, action) => {
      // state.notificationAlerts = action.payload;
      state.notificationAlerts = [action.payload, ...state.notificationAlerts];
      state.showNotificationAlerts = true;
    },
    clearNotificationAlerts: (state) => {
      state.notificationAlerts = [];
      state.showNotificationAlerts = false;
    },
    clearBackgroundProcesses: (state) => {
      state.backgroundProcesses = [];
      state.showBackgroundProcessesNotification = false;
    },

    markAsDownloaded: (state, action) => {
      const { file_name, type, file_url } = action.payload;

      if (type === 'backgroundProcess') {
        // Mark as downloaded in backgroundProcesses
        const index = state.backgroundProcesses.findIndex(
          (process) => process.file_url === file_url
        );
        if (index !== -1) {
          state.backgroundProcesses[index].downloaded = true;
        }
      } else if (type === 'report') {
        // Check if notificationAlerts is an array
        if (Array.isArray(state.notificationAlerts)) {
          const alertIndex = state.notificationAlerts.findIndex(
            (alert) => alert.file_url === file_url
          );
          if (alertIndex !== -1) {
            state.notificationAlerts[alertIndex].downloaded = true;
          }
        }
      }
    },


    decrementNotificationCount: (state, action) => {
      const { file_name, type, file_url } = action.payload;

      if (type === 'backgroundProcess') {
        // Remove the downloaded item from backgroundProcesses
        state.backgroundProcesses = state.backgroundProcesses.filter(
          (process) => process.file_url !== file_url
        );
        if (state.backgroundProcesses.length === 0) {
          state.showBackgroundProcessesNotification = false;
        }
      } else if (type === 'report') {
        // Remove the downloaded item from notificationAlerts
        state.notificationAlerts = state.notificationAlerts.filter(
          (alert) => alert.file_url !== file_url
        );
        if (state.notificationAlerts.length === 0) {
          state.showNotificationAlerts = false;
        }

      }
    },


    showNotification: (state, action) => {
      const { type, message, description, duration } = action.payload;
      notification[type]({
        message,
        description,
        duration,
      });
    },
  },
});

export const {
  showNotification,
  setBackgroundProcesses,
  clearBackgroundProcesses,
  markAsDownloaded,
  decrementNotificationCount,
  setShowBackgroundProcessesNotification,
  setNotificationAlerts,
  clearNotificationAlerts,
} = notificationSlice.actions;

export default notificationSlice.reducer;
