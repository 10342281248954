import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  paymentMethods: [],
};

const paymentMethodSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods: (state, action) => {
      state.paymentMethods = action.payload;
    },
    clearPaymentMethods: (state) => {
      state.paymentMethods = [];
    },
    refreshPaymentMethods: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.paymentMethods = state.paymentMethods.map(item => {
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});

export const { setPaymentMethods, clearPaymentMethods, refreshPaymentMethods } = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;
