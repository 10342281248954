import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {
  WEBSOCKETS_KEY,
  WEBSOCKETS_CLUSTER,
  WEBSOCKETS_TLS,
  WEBSOCKETS_HOST,
  WEBSOCKETS_PORT,
  API_PUBLIC_URL,
  WEBSOCKETS_AUTHENDPOINT
} from 'configs/AppConfig';

Pusher.logToConsole = false;

window.Pusher = Pusher;

const echo = new Echo({
  broadcaster: 'pusher',
  key: WEBSOCKETS_KEY,
  cluster: WEBSOCKETS_CLUSTER,
  forceTLS: WEBSOCKETS_TLS === true,
  wsHost: WEBSOCKETS_HOST,
  wsPort: WEBSOCKETS_PORT,
  wssPort: WEBSOCKETS_PORT,
  disableStats: true,
  enabledTransports: ['ws'],
  authEndpoint: API_PUBLIC_URL + '/api/' + WEBSOCKETS_AUTHENDPOINT,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
      Accept: 'application/json',
    },
  },
});

// Connection state bindings
echo.connector.pusher.connection.bind('connected', () => {
  console.log('Pusher connected');
});

echo.connector.pusher.connection.bind('connecting', () => {
  console.log('Pusher connecting');
});

echo.connector.pusher.connection.bind('unavailable', () => {
  console.log('Pusher unavailable');
});

echo.connector.pusher.connection.bind('failed', () => {
  console.log('Pusher failed');
});

echo.connector.pusher.connection.bind('disconnected', () => {
  console.log('Pusher disconnected');
  setTimeout(() => {
    echo.connector.pusher.connect();
  }, 5000); // Reconnect after 5 seconds
});

echo.connector.pusher.connection.bind('error', (err) => {
  console.error('Pusher error:', err);
});

export default echo;
