// billSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bills: [],
    selectedBill: null,
};

const billSlice = createSlice({
    name: 'bill',
    initialState,
    reducers: {
        setBills: (state, action) => {
            state.bills = action.payload;
        },
        clearBills: (state) => {
            state.bills = [];
        },
        setSelectedBill: (state, action) => {
            state.selectedBill = action.payload;
        },
        clearSelectedBill: (state) => {
            state.selectedBill = null;
        },
    },
});

export const { setBills, clearBills, setSelectedBill, clearSelectedBill } = billSlice.actions;
export default billSlice.reducer;
