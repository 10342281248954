import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    islands: [],
};

const islandSlice = createSlice({
    name: 'islands',
    initialState,
    reducers: {
        setIslands: (state, action) => {
            state.islands = action.payload;
        },
        clearIslands: (state) => {
            state.islands = [];
        },
        refreshIslands: (state, action) => {
            const { id, newItem, actionType } = action.payload;
            state.islands = state.islands.map(item => {
                if (item.id === id) {
                    if (actionType === 'update') {
                        return newItem;
                    } else if (actionType === 'delete') {
                        return null; // Returning null will remove the item from the array
                    }
                }
                return item;
            }).filter(item => item !== null); // Filter out deleted items
        },
    },
});

export const { setIslands, clearIslands, refreshIslands } = islandSlice.actions;
export default islandSlice.reducer;
