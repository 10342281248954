import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { store, persistor } from './store';  // Correctly import named exports
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import processRecords from 'services/OfflineAPIRequestHandler'
import NetworkStatusManager from 'services/NetworkStatusManager';
import { PersistGate } from 'redux-persist/integration/react';


const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const queryClient = new QueryClient();

function App() {

  // useEffect(() => {
  //   const queryKeys = ['products', 'orders'];
  //   processRecords(queryClient, queryKeys);


  //   // prevent refresh
  //   // const handleBeforeUnload = (event) => {
  //   //     event.preventDefault();
  //   //     event.returnValue = ''; // For Chrome
  //   // };
  //   // window.addEventListener('beforeunload', handleBeforeUnload);
  //   // return () => window.removeEventListener('beforeunload', handleBeforeUnload);

  // }, []);




  return (

    <div className="App">

      <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>

              <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={THEME_CONFIG.currentTheme}
                insertionPoint="styles-insertion-point"
              >

                <NetworkStatusManager />


                <Layouts />

              </ThemeSwitcherProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </PersistGate>
      </Provider>

    </div>
  );
}

export default App;
