// NotificationService.js
import React from 'react';
import { notification } from 'antd';

const formatErrorMessages = (messages) => {
  return Object.keys(messages).map((key, index) => {
    const messageArray = messages[key];
    return (
      <div key={index}>
        <strong>{index + 1}. {key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {messageArray.join(', ')}
      </div>
    );
  });
};

const Notify = (type, title, messages, duration) => {
  let formattedMessages;

  if (typeof messages === 'object' && !Array.isArray(messages)) {
    // Format object messages
    formattedMessages = formatErrorMessages(messages);
  } else if (Array.isArray(messages)) {
    // Format array messages
    formattedMessages = messages.map((msg, index) => (
      <div key={index}>
        <strong>{index + 1}.</strong> {msg}
      </div>
    ));
  } else {
    // Use string messages directly
    formattedMessages = messages;
  }

  notification[type]({
    message: title,
    description: <div>{formattedMessages}</div>,
    duration: duration,
  });

  // Notify doesn't render anything visible
  return null;
};

export default Notify;
