import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import fetch from 'auth/FetchInterceptor';
export const initialState = {
  outlets: [],
  outletLogoBase64: '',
};

const outletSlice = createSlice({
  name: 'outlets',
  initialState,
  reducers: {
    setOutletLogoBase64: (state, action) => {
      state.outletLogoBase64 = action.payload;
    },
    clearOutletLogoBase64: (state) => {
      state.outletLogoBase64 = '';
    },
    setOutlets: (state, action) => {
      state.outlets = action.payload;
    },
    clearOutlets: (state) => {
      state.outlets = [];
    },
    refreshOutlets: (state, action) => {
      const { id, newItem, actionType } = action.payload;
      state.outlets = state.outlets.map(item => {
        if (item.id === id) {
          if (actionType === 'update') {
            return newItem;
          } else if (actionType === 'delete') {
            return null; // Returning null will remove the item from the array
          }
        }
        return item;
      }).filter(item => item !== null); // Filter out deleted items
    },
  },
});





export const { setOutlets, clearOutlets, refreshOutlets, setOutletLogoBase64, clearOutletLogoBase64 } = outletSlice.actions;
export default outletSlice.reducer;
