
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import fetch from 'auth/FetchInterceptor'
// Assuming fetch is a utility for HTTP requests
export const useProcessItemMutation = () => {
    const mutate = async (url,method,data) => {
        return fetch({
            url: url,
            method:method,
            data: data
        });
    };

    return { mutate };
};
 