// src/store/slices/cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import Notify from 'services/NotificationService';

const initialState = {
  cart: {
    items: [],
    currency: '', // Add currency to the initial state
  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => {
      state.cart.items = action.payload.items;
      state.cart.currency = action.payload.currency; // Set currency
    },
    getCart: (state) => {
      return state.cart.items;
    },
    refreshCart: (state) => {
      state.cart.items = [];
      state.cart.currency = ''; // Clear currency
    },
    addProductToCart: (state, action) => {
      const { product, options, currency, unit_of_measure } = action.payload;

      // Check if inventory is tracked
      if (product.is_inventory_tracked === 1) {
        // If the product has variations, calculate available quantity based on variations
        if (product.has_variations === 1) {
          // Extract the option variation ID from the options payload safely
          const productOptionVariationId = options?.[0]?.value?.[0]?.inventories?.[0]?.product_option_variation_id;

          if (!productOptionVariationId) {
            Notify('error', 'Error', `Option variation ID not found`, 3);
            return;
          }

          // Find the correct option variation in the product's options
          const selectedOptionVariation = product.product_options
            ?.flatMap(option => option.product_option_variations)
            ?.find(variation => variation.id === productOptionVariationId);

          if (!selectedOptionVariation) {
            Notify('error', 'Error', `Option variation not found`, 3);
            return;
          }

          // Calculate the total available quantity for this variation
          const totalAvailableQuantity = selectedOptionVariation?.inventories?.reduce((total, inventory) => {
            const available = inventory.quantity - inventory.quantity_sold;
            return total + (available > 0 ? available : 0); // Ensure no negative or zero quantities
          }, 0);

          if (!totalAvailableQuantity || totalAvailableQuantity < 1) {
            Notify('error', 'Error', `Quantity exceeds available stock!`, 3);
            return;
          }

          console.log('Total available quantity for variation:', totalAvailableQuantity);
        } else {
          // For products without variations, calculate available stock from product inventory
          const totalAvailableQuantity = product.inventory?.reduce((total, inventory) => {
            const available = inventory.quantity - inventory.quantity_sold;
            return total + (available > 0 ? available : 0);
          }, 0);

          if (!totalAvailableQuantity || totalAvailableQuantity < 1) {
            Notify('error', 'Error', `Quantity exceeds available stock!`, 3);
            return;
          }

          console.log('Total available quantity for product:', totalAvailableQuantity);
        }
      }

      // Check if the item already exists in the cart
      const existingItem = state.cart.items.find(item =>
        item.product.id === product.id && JSON.stringify(item.options) === JSON.stringify(options)
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cart.items.push({ product, options, quantity: 1, unit_of_measure, currentQuantity: 1 });
      }

      state.cart.currency = currency;
    },



    updateQuantity: (state, action) => {
      const { productId, quantity, options, currentQuantity } = action.payload;

      // Find the correct item in the cart
      const existingItem = state.cart.items.find(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
      );

      if (!existingItem) {
        return;
      }

      // Handle inventory checks if the product is tracked
      if (existingItem.product.is_inventory_tracked === 1) {
        if (existingItem.product.has_variations === 1) {
          const productOptionVariationId = options?.[0]?.value?.[0]?.inventories?.[0]?.product_option_variation_id;

          if (!productOptionVariationId) {
            Notify('error', 'Error', `Option variation ID not found`, 3);
            return;
          }

          // Find the correct variation in the product options
          const selectedOptionVariation = existingItem.product.product_options
            ?.flatMap(option => option.product_option_variations)
            ?.find(variation => variation.id === productOptionVariationId);

          if (!selectedOptionVariation) {
            Notify('error', 'Error', `Option variation not found`, 3);
            return;
          }

          // Calculate the total available quantity for this variation
          const totalAvailableQuantity = selectedOptionVariation?.inventories?.reduce((total, inventory) => {
            const available = inventory.quantity - inventory.quantity_sold;
            return total + (available > 0 ? available : 0);
          }, 0);

          if (quantity > totalAvailableQuantity) {
            Notify('error', 'Error', `Quantity exceeds available stock!`, 3);
            return;
          }
        } else {
          // For products without variations, check total available stock
          const totalAvailableQuantity = existingItem.product.inventory?.reduce((total, inventory) => {
            const available = inventory.quantity - inventory.quantity_sold;
            return total + (available > 0 ? available : 0);
          }, 0);

          if (quantity > totalAvailableQuantity) {
            Notify('error', 'Error', `Quantity exceeds available stock!`, 3);
            return;
          }
        }
      }

      // Update the cart with the new quantity
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, quantity, currentQuantity }
          : item
      );
    },


    updateUnitOfMeasure: (state, action) => {
      const { productId, unit_of_measure, options } = action.payload;
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, unit_of_measure }
          : item
      );
    },
    removeProductFromCart: (state, action) => {
      const { productId, options } = action.payload;
      state.cart.items = state.cart.items.filter(item => !(item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)));
    },
    clearProductCart: (state, action) => {
      state.cart.items = [];
    },
    updateDiscount: (state, action) => {
      const { productId, discount, discountType, options } = action.payload;
      state.cart.items = state.cart.items.map(item =>
        item.product.id === productId && JSON.stringify(item.options) === JSON.stringify(options)
          ? { ...item, discount, discountType }
          : item
      );
    },
    setCurrency: (state, action) => {
      state.cart.currency = action.payload;
    },
  },
});

export const { setCart, getCart, refreshCart, addProductToCart, updateQuantity, updateUnitOfMeasure, clearProductCart, removeProductFromCart, updateDiscount, setCurrency } = cartSlice.actions;
export default cartSlice.reducer;
