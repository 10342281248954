import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    runningNumbers: [],
    filteredRunningNumbers: [],

};

const runningNumberSlice = createSlice({
    name: 'runningNumbers',
    initialState,
    reducers: {
        setRunningNumbers: (state, action) => {
            state.runningNumbers = action.payload;
        },
        setFilteredRunningNumbers: (state, action) => {
            state.filteredRunningNumbers = action.payload;
        },
        clearFilteredRunningNumbers: (state) => {
            state.filteredRunningNumbers = [];
        },
        clearRunningNumbers: (state) => {
            state.runningNumbers = [];
        },

    },
});

export const { setRunningNumbers, clearRunningNumbers, setFilteredRunningNumbers, clearFilteredRunningNumbers } = runningNumberSlice.actions;
export default runningNumberSlice.reducer;
