import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    dashboardData: null,
    loading: true,
    error: null,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardData: (state, action) => {
            state.dashboardData = action.payload;
            state.loading = false;
            state.error = null;  // Clear any previous error when data is successfully fetched
        },
        setLoading: (state) => {
            state.loading = true;
            state.error = null;  // Clear any previous error when starting a new request
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setDashboardData, setLoading, setError } = dashboardSlice.actions;
export default dashboardSlice.reducer;
