// store/slices/rolesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const rolesSlice = createSlice({
    name: 'roles',
    initialState: {
        roles: [],
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        addRole: (state, action) => {
            state.roles.push(action.payload);
        },
        updateRole: (state, action) => {
            const index = state.roles.findIndex((role) => role.id === action.payload.id);
            if (index !== -1) {
                state.roles[index] = action.payload.newItem;
            }
        },
        deleteRole: (state, action) => {
            state.roles = state.roles.filter((role) => role.id !== action.payload);
        },
    },
});

export const { setRoles, addRole, updateRole, deleteRole } = rolesSlice.actions;
export default rolesSlice.reducer;
