import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import storage from 'redux-persist/lib/storage'; // defaults to using localStorage
import rootReducer from './rootReducer';
import indexedDBStorageEngine from '../configs/IndexedDBStorageEngine';


const customIndexedDBStorage = {
  getItem: (key) => indexedDBStorageEngine.getItem(key),  // Use the methods from indexedDBStorageEngine
  setItem: (key, value) => indexedDBStorageEngine.setItem(key, value), // Reference the methods from indexedDBStorageEngine
  removeItem: (key) => indexedDBStorageEngine.removeItem(key), // Same here
};

const persistConfig = {
  key: 'root',
  storage: customIndexedDBStorage,
  // whitelist: ['users'],
  // blacklist: ['users'],
  // Optionally you can add whitelist or blacklist to choose what to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

const persistor = persistStore(store);

store.asyncReducers = {};

// This function is used to inject reducers dynamically
export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  // Use persistedReducer with dynamic injection
  store.replaceReducer(persistReducer(persistConfig, rootReducer(store.asyncReducers)));
  return store;
}

export { store, persistor };  // export persistor alongside store
