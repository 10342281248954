import { createSlice } from '@reduxjs/toolkit';

const orderTypesSlice = createSlice({
    name: 'orderTypes',
    initialState: [], // Make sure this is an array
    reducers: {
        setOrderTypes: (state, action) => {
            return action.payload; // Replace state with the order types data
        },
        clearOrderTypes: (state) => {
            return [];
        },
    },
});

export const { setOrderTypes, clearOrderTypes } = orderTypesSlice.actions;
export default orderTypesSlice.reducer;
