import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'PLATTO CLOUD';
export const API_ENDPOINT_URL = env.API_ENDPOINT_URL
export const API_ASSET_URL = env.API_ASSET_URL
export const API_PUBLIC_URL = env.API_PUBLIC_URL
export const PRINTER_URL = env.PRINTER_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '';
export const REDIRECT_URL_KEY = 'redirect'
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/index`;
export const UNAUTHENTICATED_ENTRY = '/login'

export const WEBSOCKETS_KEY = '391e99ae90fe6e9010102cbcdb1b691e'
export const WEBSOCKETS_CLUSTER = 'mt1';
export const WEBSOCKETS_TLS = env.WEBSOCKETS_TLS
export const WEBSOCKETS_HOST = env.WEBSOCKETS_HOST
export const WEBSOCKETS_PORT = env.WEBSOCKETS_PORT
export const WEBSOCKETS_AUTHENDPOINT = 'broadcasting/auth'


export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};
